<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <div>
      <v-container fluid class="fill-height">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="7" md="7">
            <h1>
              Categorías de
              <span class="font-weight-light">
                {{ getBusinessName }}
              </span>
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row justify="end">
              <v-btn
                color="primary"
                class="ma-2 white--text mr-5"
                @click="modalSort = true"
                :loading="loading"
              >
                <v-icon right dark class="mr-3">fas fa-bars</v-icon>
                Ordenar
              </v-btn>

              <v-btn
                color="primary"
                class="ma-2 white--text mr-5"
                @click="addMenu"
                :loading="loading"
              >
                <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                Crear nueva categoría
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="getMenu"
              :search="$store.state.search"
              :items-per-page="15"
              fixed-header
              :loading="loading"
              :sort-by="['position']"
              :sort-desc="[false, true]"
              item-key=".key"
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, 250],
              }"
            >
              <template v-slot:[`item.active`]="{ item }">
                <div style="display: flex ; justify-content: center;!important">
                  <v-switch
                    :loading="loading"
                    @change="activeUpdate(item, { active: item.active })"
                    v-model="item.active"
                  ></v-switch>
                </div>
              </template>

              <template v-slot:[`item.isAvailable`]="{ item }">
                <div style="display: flex ; justify-content: center;!important">
                  <v-switch
                    @change="
                      activeUpdate(item, { isAvailable: item.isAvailable })
                    "
                    v-model="item.isAvailable"
                    :loading="loading"
                  ></v-switch>
                </div>
              </template>

              <template v-slot:[`item.isFreeDelivery`]="{ item }">
                <v-switch
                  @change="handleFreeDelivery(item)"
                  v-model="item.isFreeDelivery"
                >
                </v-switch>
              </template>

              <template v-slot:[`item.options`]="{ item }">
                <v-btn
                  :loading="loading"
                  @click="editSection(item)"
                  small
                  color="primary"
                >
                  Editar
                </v-btn>

                <v-btn
                  class="ml-2"
                  :loading="loading"
                  @click="editSchedule(item)"
                  small
                  color="primary"
                >
                  Horarios
                </v-btn>

                <v-btn
                  class="ma-2 white--text"
                  :loading="loading"
                  @click="
                    itemToDelete = item;
                    modalDelete = true;
                  "
                  small
                  color="red darken-4"
                >
                  Eliminar
                </v-btn>
              </template>

              <template v-slot:loading>
                <div class="ma-2" style="position: relative">
                  <div
                    style="
                      position: absolute;
                      z-index: 999;
                      width: 100%;
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <lottie
                      :options="defaultOptions"
                      :height="150"
                      :width="150"
                      v-on:animCreated="handleAnimation"
                    />
                    <p
                      :class="
                        $vuetify.theme.dark
                          ? 'subtitle-1 primary--text'
                          : 'subtitle-1 black--text'
                      "
                    >
                      Cargando Data
                    </p>
                  </div>
                  <v-skeleton-loader
                    ref="skeleton"
                    type="table-tbody"
                    class="mx-auto d-none d-md-block"
                  ></v-skeleton-loader>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="addDialog" v-if="addDialog" max-width="600px">
      <section-edit
        @success="handleSuccess"
        @cancel="addDialog = false"
        :section="sectionSelected"
        :position="getMenu.length"
      />
    </v-dialog>

    <v-dialog v-model="scheduleDialog" v-if="scheduleDialog" max-width="80%">
      <edit-schedule
        @success="handleSuccess"
        @cancel="scheduleDialog = false"
        :section="sectionSelected"
        :position="getMenu.length"
      />
    </v-dialog>

    <v-dialog scrollable persistent v-model="modalSort" max-width="500px">
      <sort
        :key="Math.random()"
        @success="handleSort"
        :categories="menu"
        @cancel="modalSort = false"
      ></sort>
    </v-dialog>

    <!-- modal delete  -->
    <v-dialog max-width="400" v-if="itemToDelete" v-model="modalDelete">
      <v-card>
        <v-card-title class="headline">
          Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3">
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">
            {{ itemToDelete.name }}
          </span>
          ?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 10px !important"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
// import { Parser } from "json2csv";
import { mapState } from "vuex";
// import sectionDetails from "./section-details";
import editSection from "./edit-section";
// import moment from "moment-timezone";
// import "moment/locale/es";
// moment.locale("es");
// import { Button } from "ant-design-vue";
// import { TimePicker } from "ant-design-vue";
import sort from "./sort";
import ledgerMixin from "@/mixins/ledgerMixin";
import editScheduleC from "./edit-schedule";

export default {
  name: "Section",
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    // "section-details": sectionDetails,
    "section-edit": editSection,
    "edit-schedule": editScheduleC,
    // "a-time-picker": TimePicker,
    // "a-button": Button,
    sort,
  },
  data() {
    return {
      business: null,
      // item: 0,
      // rndKey: 0,
      menu: [],
      itemToDelete: null,
      // time: null,
      // menu2: false,
      // waiting: true,
      // daySelected: "monday",
      modalDelete: false,
      // modalAddSection: false,
      modalSort: false,
      headers: [
        { text: "Posición", value: "position" },
        { text: "Nombre", value: "name" },
        {
          text: "Visible en el app",
          value: "active",
          align: "center",
          justify: "center",
        },
        {
          text: "Disponible",
          value: "isAvailable",
          align: "center",
          justify: "center",
        },
        {
          text: "Envío gratis",
          value: "isFreeDelivery",
          class: "nowrap",
        },
        { text: "", value: "options", align: "end" },
      ],
      loading: true,

      sectionSelected: {},
      addDialog: false,
      snackbarText: "",
      snackbar: "",
      scheduleDialog: false,
    };
  },
  watch: {
    selectedBusiness() {
      this.menu = [];
      this.getBusinessData();
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
    getBusinessName() {
      return this.$store.state.selectedBusiness.shortName;
    },
    getMenu() {
      return this.menu.filter((item) => !item.deleted || item.deleted == false);
    },
  },
  methods: {
    handleSort() {
      this.snackbarText =
        "Posiciones de las categorías actualizadas exitosamente.";
      this.snackbar = true;
    },
    async handleFreeDelivery(item) {
      let batchData = [];

      this.loading = true;

      let products = await db
        .collection("products")
        .where("menu", "array-contains", item[".key"])
        .get();

      for (let i = 0; i < products.docs.length; i++) {
        const productId = products.docs[i].id;

        batchData.push({
          reference: db.collection("products").doc(productId),
          data: {
            isFreeDelivery: item.isFreeDelivery ? true : false,
          },
        });
      }

      for (let i = 0; i < batchData.length; i = i + 490) {
        let batch = db.batch();
        let itemsToUpdate = batchData.slice(i, i + 490);

        itemsToUpdate.forEach((item) => {
          batch.update(item.reference, item.data);
        });

        await batch.commit();
        batch = null;
      }

      // let batch = db.batch();

      // for (let i = 0; i < batchData.length; i++) {
      //   const item = batchData[i];
      //   batch.update(item.reference, item.data);
      // }

      // await batch.commit();

      await db
        .collection("businesses")
        .doc(this.selectedBusiness[".key"])
        .collection("menu")
        .doc(item[".key"])
        .update({
          isFreeDelivery: item.isFreeDelivery ? true : false,
        });

      await db
        .collection("businesses")
        .doc(this.selectedBusiness[".key"])
        .update({
          freeShippingProductsIsAvailable: item.isFreeDelivery ? true : false,
        });

      this.loading = false;
      this.snackbarText = `Envío gratis ${
        item.isFreeDelivery ? "aplicado a la" : "desactivado de la"
      } categoría ${item.name}`;
      this.snackbar = true;
    },
    handleSuccess() {
      this.snackbarText = "Información actualizada exitosamente.";
      this.addDialog = false;
      this.scheduleDialog = false;
      this.snackbar = true;
    },
    // showSchedule(day) {
    //   this.waiting = false;
    //   this.daySelected = day;

    //   setTimeout(() => {
    //     this.waiting = true;
    //   }, 100);
    // },
    // handleClose(order, index) {
    //   if (order == 1)
    //     this.newItem.schedule[this.daySelected][index].activeOpens = false;
    //   else this.newItem.schedule[this.daySelected][index].activeCloses = false;
    // },
    // changeOpens(time, index) {
    //   this.newItem.schedule[this.daySelected][index].opensAt =
    //     moment(time).format("HH:mm:ss");
    // },
    // changeCloses(time, index) {
    //   this.newItem.schedule[this.daySelected][index].closesAt =
    //     moment(time).format("HH:mm:ss");
    // },
    activeUpdate(item, data) {
      this.loading = true;

      let docRef = db
        .collection(`businesses/${this.selectedBusiness[".key"]}/menu`)
        .doc(item[".key"]);

      docRef
        .update(data)
        .then(() => {
          this.snackbarText = `Sección de ${item.name} ${
            item.active ? "activa" : "inactiva"
          }`;
          this.snackbar = true;
          this.loading = false;

          this.createLedger({
            source_id: docRef.id,
            source_type: "menu",
            currentValue: { active: item.active },
            prevValue: { active: !item.active },
          });
        })
        .catch((err) => {
          item.active = !item.active;
          this.snackbarText = `Ocurrió un error, inténtelo nuevamente.`;
          this.snackbar = true;
          this.loading = false;
        });
    },
    editSection(item) {
      this.addDialog = true;
      this.sectionSelected = item;
    },

    editSchedule(item) {
      this.sectionSelected = item;
      this.scheduleDialog = true;
    },

    addMenu() {
      this.addDialog = true;
      this.sectionSelected = null;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        db.collection(`businesses/${this.selectedBusiness[".key"]}/menu`)
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Elemento borrado";

            this.createLedger({
              source_id: this.itemToDelete[".key"],
              source_type: "menu",
              currentValue: {
                deleted: true,
                active: false,
                deletedAt: new Date(),
                deletedBy: this.$store.state.user[".key"],
              },
              prevValue: this.itemToDelete,
            });
          })
          .catch(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Occrrió un error, inténtelo nuevamente.";
          });
      }
    },
    async getBusinessData() {
      if (!this.selectedBusiness) {
        this.$router.push({ path: "/" });
      }

      this.loading = true;
      // this.tagsToSearch = "";
      this.$store.state.search = "";

      await this.$binding(
        "business",
        db.collection("businesses").doc(this.selectedBusiness[".key"])
      );

      await this.$binding(
        "menu",
        db.collection(`businesses/${this.selectedBusiness[".key"]}/menu`)
      );

      // await Promise.all([business, menu]);
      this.loading = false;
    },
  },

  mounted() {
    // document
    //   .getElementsByClassName("v-data-table__wrapper")[0]
    //   .addEventListener("scroll", function(e) {
    //     document.body.scrollTop = document.documentElement.scrollTop = document.getElementsByClassName(
    //       "v-data-table__wrapper"
    //     )[0].scrollTop;
    //   });

    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "sección de productos";

    this.getBusinessData();
  },

  destroyed() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
};
</script>

<style scoped>
.table-sections {
  margin: 5%;
}
.add-schedule {
  display: flex;
  justify-content: center;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.note {
  border: 1px solid #f0703da2;
  padding: 10px;
  background-color: #8080801f;
}
.theme--light.v-list {
  background: white !important;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.add-schedule {
  display: flex;
  justify-content: flex-end;
}
.fa-times {
  color: gray;
}
.delete-schedule-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.delete-schedule {
  margin: 0;
  cursor: pointer;
  padding-right: 5px;
  color: #f06f3d;
}
.save-btn {
  margin-top: 20px;
}
.actions {
  position: fixed;
  bottom: 0;
  width: 80%;
  bottom: 20px;
  padding-right: 20px;
  background-color: white;
}
.fixed-position {
  z-index: 100;
}
</style>
