<template>
  <v-card>
    <v-card-title class="grid-close">
      <span class="headline"
        >Horario de <b>{{ section.name }}</b></span
      >
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        :loading="loading"
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <schedule-c :loading="loading" :schedule="schedule" />
    </v-card-text>
    <v-card-actions class="btn-actions">
      <v-btn dark rounded color="primary" class="pa-5" @click="updateSchedule"
        >Actualizar horario</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { db } from "@/firebase";
import { mapState } from "vuex";
import scheduleC from "@/components/own-schedule";

export default {
  name: "section-edit",
  props: ["section"],
  components: {
    scheduleC,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: false,
      schedule: {
        activeMonday: false,
        activeTuesday: false,
        activeWednesday: false,
        activeThursday: false,
        activeFriday: false,
        activeSaturday: false,
        activeSunday: false,
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      },
    };
  },

  computed: {
    ...mapState(["selectedBusiness"]),
  },
  mounted() {
    if (this.section.schedule && this.section.schedule.monday) {
      this.schedule = Object.assign({}, this.section.schedule);
    }
  },
  methods: {
    updateSchedule() {
      this.loading = true;
      let days = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];

      days.forEach((day) => {
        this.schedule[day] = this.schedule[day].filter(
          (schedule) => schedule.opensAt && schedule.closesAt
        );
      });

      db.collection(`businesses/${this.selectedBusiness[".key"]}/menu`)
        .doc(this.section[".key"])
        .update({
          schedule: this.schedule,
        })
        .then(() => {
          this.$emit("success");
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error desconocido, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.btn-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 40px 30px 0;
}
</style>

