<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <!-- <v-overlay class="loading-center" :value="save">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay> -->

    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">Editar categoría</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          :loading="loading"
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p>Nombre de la categoría</p>
              <input
                class="control-input"
                type="text"
                placeholder="Nombre se la categoría de productos"
                v-model="editedSection.name"
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="6">
              <v-row justify="start" class="mt-1">
                <v-switch
                  label="Visible en el app"
                  :loading="loading"
                  v-model="editedSection.active"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6">
              <v-row justify="end">
                <v-btn
                  :loading="loading"
                  class="save-btn"
                  color="primary"
                  @click="section ? update() : save()"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
// import lottieSettings from "@/mixins/lottieMixin";
// import moment from "moment-timezone";
import { mapState } from "vuex";
import { Button } from "ant-design-vue";
import { TimePicker } from "ant-design-vue";
import ledgerMixin from "@/mixins/ledgerMixin";
// import schedule from "@/components/own-schedule";

export default {
  name: "section-edit",
  props: ["section", "position"],
  mixins: [ledgerMixin],
  components: {
    lottie: Lottie,
    "a-time-picker": TimePicker,
    "a-button": Button,
    // schedule,
  },
  data() {
    return {
      editedSection: { active: true, name: "" },
      // save: false,
      // positionUsed: false,
      // waiting: true,
      snackbar: false,
      loading: true,
      snackbarText: "",
      // daySelected: "monday",
      // item: 0,
      // scheduleEdited: {},
      // time: null,
      // menu2: false,
      baseSchedule: {
        activeMonday: false,
        activeTuesday: false,
        activeWednesday: false,
        activeThursday: false,
        activeFriday: false,
        activeSaturday: false,
        activeSunday: false,
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      },
      // prevSection: {},
      // prevSchedule: {},
    };
  },

  computed: {
    ...mapState(["selectedBusiness", "user"]),
    // getSchedule() {
    //   return this.daySelected ? this.scheduleEdited[this.daySelected] : [];
    // },
  },
  mounted() {
    // this.scheduleEdited = Object.assign({}, this.schedule);
    if (this.section) {
      this.editedSection = Object.assign({}, this.section);
    }
    this.loading = false;
    // this.prevSection = Object.assign({}, this.section);
    // this.prevSchedule = Object.assign({}, this.schedule);
  },
  methods: {
    save() {
      if (!this.editedSection.name) {
        this.snackbar = true;
        this.snackbarText = "Ingrese un nombre para la categoría";
        return;
      }
      this.loading = true;

      db.collection(`businesses/${this.selectedBusiness[".key"]}/menu`)
        .add({
          name: this.editedSection.name,
          active: this.editedSection.active,
          isAvailable: true,
          deleted: false,
          createdAt: new Date(),
          createdBy: this.user[".key"],
          position: this.position + 1,
          schedule: {
            activeMonday: false,
            activeTuesday: false,
            activeWednesday: false,
            activeThursday: false,
            activeFriday: false,
            activeSaturday: false,
            activeSunday: false,
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
          },
        })
        .then(() => {
          this.$emit("success");
        })
        .catch((err) => {
          console.log(err);
          this.save = false;
          this.snackbar = true;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
        });
    },
    update() {
      if (!this.editedSection.name) {
        this.snackbar = true;
        this.snackbarText = "Ingrese un nombre para la categoría";
        return;
      }
      this.loading = true;

      db.collection(`businesses/${this.selectedBusiness[".key"]}/menu`)
        .doc(this.section[".key"])
        .update({
          name: this.editedSection.name,
          active: this.editedSection.active,
        })
        .then(() => {
          this.createLedger({
            source_id: this.section[".key"],
            source_type: "menu",
            currentValue: Object.assign({}, this.section),
            prevValue: Object.assign({}, this.prevSection),
          });

          this.$emit("success");
        })
        .catch((err) => {
          console.log(err);
          this.save = false;
          this.snackbar = true;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
        });
    },
  },
};
</script>

<style scoped>
.btn-modal-add-tags {
  justify-content: center;
}
.add-tag-ico {
  font-size: 30px !important;
  padding: 10px;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.fa-times {
  color: gray;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.add-schedule {
  display: flex;
  justify-content: flex-end;
}
.note {
  border: 1px solid #f0703da2;
  padding: 10px;
  background-color: #8080801f;
}
.theme--light.v-list {
  background: white !important;
}
.fa-times {
  color: gray;
}
.delete-schedule-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.delete-schedule {
  margin: 0;
  cursor: pointer;
  padding-right: 5px;
  color: #f06f3d;
}
.save-btn {
  margin-top: 20px;
}
.actions {
  position: fixed;
  bottom: 0;
  width: 80%;
  bottom: 20px;
  padding-right: 20px;
  background-color: white;
}
.fixed-position {
  z-index: 100;
}
</style>
